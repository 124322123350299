.download-table .ant-table table {
  font-size: 12px;
}

.download-table .ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.download-table .ant-table.ant-table-small .ant-table-thead > tr > th,
.download-table .ant-table.ant-table-small .ant-table-tbody > tr > td,
.download-table .ant-table.ant-table-small tfoot > tr > th,
.download-table .ant-table.ant-table-small tfoot > tr > td {
  padding: 2px 4px;
}

.download-table .ant-page-header-content {
  padding-top: 0px;
}
